<template>
  <div id="app">
    <div class="main">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

  async created() {
    this.appendScript();
    this.appendUserwayAccessibilityBar();
  },

  methods: {
    appendScript() {
      const recaptcha = document.createElement('script');

      recaptcha.setAttribute('type', 'text/javascript');
      recaptcha.setAttribute(
        'src',
        `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`
      );
      document.querySelector('body').appendChild(recaptcha);
    },
    appendUserwayAccessibilityBar() {
      const uway = document.createElement('script');

      uway.setAttribute('type', 'text/javascript');
      uway.setAttribute(
        'src',
        'https://cdn.userway.org/widget.js'
      );
      uway.setAttribute('data-account', process.env.VUE_APP_USERWAY_ACCOUNT);
      document.querySelector('body').appendChild(uway);

    }
  }
};
</script>

<style lang="scss">
/**
  *
  * Normalize from Tailwind
  * https://unpkg.com/tailwindcss@1.1.4/dist/base.css
  *
  */
@import "@/assets/sass/base/_normalize.scss";

/**
  *
  * Base
  *
  */
@import "@/assets/sass/base/_container.scss";
@import "@/assets/sass/base/_fonts.scss";

/**
  *
  * Project
  *
  */
@import "@/assets/sass/project/_buttons.scss";
@import "@/assets/sass/project/_form.scss";
@import "@/assets/sass/project/_animations.scss";

body,
html {
  max-width: 100%;
  overflow-x: hidden;
}

#app {
  font-family: $font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  width: 100vw;
  background-color: $white;
}

.main {
  width: 100%;
}

body .uwy {
  z-index: 60 !important;
}

body .uwy.userway_p1 .uai {
  left: calc(100% - 10px) !important;
  top: 10% !important;

  @include screen(tablet-big-up) {
    top: 15% !important;
  }
}

body div[vw] {
  top: 45% !important;
  z-index: 35 !important;

  @include screen(tablet-big-up) {
    top: 53% !important;
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.rc-anchor {
  flex-direction: row-reverse !important;
}
</style>
